import api from './api';

// Autenticação
export const login = (params = {}) =>
	api.post('v1/administrador/login', params);

export const logout = (params = {}) =>
	api.post('v1/administrador/logout', params);

// Administradores
export const getAdministrador = (id, params = {}) =>
	api.get(`v1/administrador/${id}`, params);

export const getAdministradores = (params = {}) =>
	api.get('v1/administradores', params);

export const createAdministrador = (data = {}) =>
	api.post('v1/administradores', data);

export const updateAdministrador = (id, data = {}) =>
	api.put(`v1/administrador/${id}`, data);

// Usuários
export const getUsers = (params = {}) => api.get('v1/usuarios', params);
export const getUser = (id, params = {}) => api.get(`v1/usuario/${id}`, params);
export const updateUser = (id, params = {}) =>
	api.put(`v1/usuario/${id}`, params);
export const createUser = (params = {}) => api.post('v1/usuarios', params);
export const deleteUser = (id, params = {}) =>
	api.delete(`v1/usuarios/${id}`, params);

// Configurações do site
export const getConfiguracoes = (params = {}) =>
	api.get('v1/configuracoes', params);
export const atualizarConfiguracao = (key, params = {}) =>
	api.put(`v1/configuracao/${key}`, params);
export const updateLoginBackground = data =>
	api.put(`v1/atualizar-imagem-login`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

// Categorias
export const getCategories = (params = {}) =>
	api.get('v1/categorias', { params });
export const getCategory = id => api.get(`v1/categoria/${id}`);
export const updateCategory = (id, params = {}) =>
	api.put(`v1/categoria/${id}`, params);
export const createCategory = (params = {}) =>
	api.post('v1/categorias', params);
export const deleteCategory = (id, params = {}) =>
	api.delete(`v1/categoria/${id}`, params);

// Coleções
export const getCollections = (params = {}) => api.get('v1/colecoes', { params });
export const getCollection = (id, params = {}) =>
	api.get(`v1/colecao/${id}`, { params });
export const updateCollection = (id, params = {}) =>
	api.put(`v1/colecao/${id}`, params);
export const createCollection = (params = {}) =>
	api.post('v1/colecoes', params);
export const deleteCollection = (id, params = {}) =>
	api.delete(`v1/colecao/${id}`, params);

//Banners
export const getBanners = (params = {}) => api.get('v1/banners', params);
export const getBanner = bannerId => api.get(`v1/banner/${bannerId}`);
export const updateBanner = (bannerId, params) =>
	api.put(`v1/banner/${bannerId}`, params);
export const createBanner = (params = {}) => api.post('v1/banners', params);
export const deleteBanner = id => api.delete(`v1/banner/${id}`);
export const associarImagemBanner = (bannerId, data) =>
	api.post(`v1/banner/${bannerId}/imagem`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

//Produtos
export const gerarQrCodes = data => api.post('v1/produtos/gerarqrcode', data);
export const importProducts = (params = {}) =>
	api.post('v1/importar-produtos', params);
export const atualizarProdutos = (params = {}) =>
	api.get('v1/atualizar-produtos', params);
export const getProducts = (params = {}) => api.get('v1/produtos', params);
export const getImportedProducts = (params = {}) =>
	api.get('v1/produtos-importados', params);
export const getProduct = (id, params = {}) =>
	api.get(`v1/produto/${id}`, params);
export const updateImages = (id, data) =>
	api.post(`v1/produto/${id}/fotos`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
export const removeImage = (imageId, produtoId) =>
	api.delete(`v1/produto/${produtoId}/foto/${imageId}`);

export const updateProduct = (id, data) => api.put(`v1/produto/${id}`, data);
export const atualizarEstoque = data => api.post('v1/atualizar-estoque', data);
export const updateProductCover = (id, fotoId, params = {}) => api.put(`v1/produto/${id}/atualizar-capa/${fotoId}`, { params })

// Notificacoes
export const getNotifications = (params = {}) =>
	api.get('v1/notificacoes', params);
export const getNotification = id => api.get(`v1/notificacao/${id}`);
export const updateNotification = (id, params = {}) =>
	api.put(`v1/notificacao/${id}`, params);
export const createNotification = (params = {}) =>
	api.post('v1/notificacoes', params);
export const deleteNotification = (id, params = {}) =>
	api.delete(`v1/notificacao/${id}`, params);
