import React, { useState, useEffect } from 'react';
import { UserCard } from 'components/Card';
import { MdClearAll, MdExitToApp, MdPersonPin, MdPerson } from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { logout, getUserName, getUserEmail } from 'services/auth';
import { withRouter } from 'react-router-dom';

const bem = bn.create('header');

function Header(props) {
  const { history } = props;
  const [isOpenUserCardPopover, setisOpenUserCardPopover] = useState(false);
  const [isNotificationConfirmed, setisNotificationConfirmed] = useState(false);
  const [isOpenNotificationPopover, setisOpenNotificationPopover] = useState(
    false,
  );

  function toggleNotificationPopover() {
    setisOpenNotificationPopover(!isOpenNotificationPopover);

    if (!isNotificationConfirmed) {
      setisNotificationConfirmed(true);
    }
  }

  function toggleUserCardPopover() {
    setisOpenUserCardPopover(!isOpenUserCardPopover);
  }

  function handleSidebarControlButton(event) {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  }

  function doLogout() {
    logout();
    history.push('/admin/login');
  }

  return (
    <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button outline onClick={handleSidebarControlButton}>
          <MdClearAll size={25} />
        </Button>
      </Nav>

      <Nav navbar className={bem.e('nav-right')}>
        <NavItem>
          <NavLink id="Popover2">
            <MdPerson
              size={25}
              className="text-secondary can-click"
              onClick={toggleNotificationPopover}
            />
          </NavLink>
          <Popover
            placement="bottom-end"
            isOpen={isOpenUserCardPopover}
            toggle={toggleUserCardPopover}
            target="Popover2"
            className="p-0 border-0"
            style={{ minWidth: 250 }}
          >
            <PopoverBody className="p-0 border-light">
              <UserCard
                title={getUserName() || ''}
                subtitle={getUserEmail() || ''}
                className="border-light"
              >
                <ListGroup flush>
                  <ListGroupItem
                    onClick={() => doLogout()}
                    tag="button"
                    action
                    className="border-light"
                  >
                    <MdExitToApp /> Sair
                  </ListGroupItem>
                </ListGroup>
              </UserCard>
            </PopoverBody>
          </Popover>
        </NavItem>
      </Nav>
    </Navbar>
  );
}

export default withRouter(Header);
