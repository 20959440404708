export const TOKEN_KEY = 'pd.@qRm#*w<j""?6xb5';
export const USER_KEY = 'pd.userinfo';
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getRefreshToken = () => {
  try {
    return JSON.parse(localStorage.getItem(TOKEN_KEY))['refresh_token'];
  } catch (e) {
    return e;
  }
};

export function getAccessToken() {
  try {
    return JSON.parse(localStorage.getItem(TOKEN_KEY))['access_token'];
  } catch (e) {
    return e;
  }
}

export const getUserName = () => {
  try {
    const name = JSON.parse(localStorage.getItem(USER_KEY))['nome'];
    return name;
  } catch (e) {
    return '';
  }
};
export const getUserEmail = () => {
  try {
    const email = JSON.parse(localStorage.getItem(USER_KEY))['email'];
    return email;
  } catch (e) {
    return '';
  }
};
export const setToken = token =>
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
export const setUserInfo = data => {
  localStorage.setItem(USER_KEY, JSON.stringify(data));
};
export const removeAccessToken = () =>
  JSON.parse(localStorage.removeItem(TOKEN_KEY))['access_token'];
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
