import axios from 'axios';
import { setToken, getRefreshToken, getAccessToken } from './auth';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

// const BASE_URL = 'http://localhost:5001/';
const BASE_URL = 'http://api.web10f66.kinghost.net/';
// const BASE_URL = 'http://api.parisdecor.com.br/';

const api = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

const refresh = axios.create({
	headers: {
		'Content-Type': 'application/json',
	},
});

api.interceptors.request.use(request => {
	request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
	return request;
});

const refreshAuthLogic = failedRequest =>
	refresh
		.post(
			`${BASE_URL}v1/refresh/token`,
			{},
			{ headers: { Authorization: `Bearer ${getRefreshToken()}` } },
		)
		.then(tokenRefreshResponse => {
			setToken(tokenRefreshResponse.data);
			failedRequest.response.config.headers['Authorization'] =
				'Bearer ' + tokenRefreshResponse.data.access_token;
			return Promise.resolve();
		});

createAuthRefreshInterceptor(api, refreshAuthLogic, {
	retryInstance: refresh,
});

export default api;
