import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import './styles/override_quill.scss';
import './styles/reduction.scss';

import PrivateRoute from './components/PrivateRouter';
import Login from './pages/Login';

const NotFoundPage = React.lazy(() => import('pages/NotFound'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const SettingsPage = React.lazy(() => import('pages/Settings'));
const UsersPage = React.lazy(() => import('pages/Users'));
const UsersEdit = React.lazy(() => import('pages/Users/Edit'));
const UsersAdd = React.lazy(() => import('pages/Users/Add'));
const BannersPage = React.lazy(() => import('pages/Banners'));
const BannerAdd = React.lazy(() => import('pages/Banners/Add'));
const BannerEdit = React.lazy(() => import('pages/Banners/Edit'));
const ProductsPage = React.lazy(() => import('pages/Products'));
const ProductEdit = React.lazy(() => import('pages/Products/Edit'));
const ProductImagesList = React.lazy(() => import('pages/Products/Images'));
const ProductsImport = React.lazy(() => import('pages/Products/Import'));
const AdminsPage = React.lazy(() => import('pages/Admins'));
const AdminsAdd = React.lazy(() => import('pages/Admins/Add'));
const AdminsEdit = React.lazy(() => import('pages/Admins/Edit'));
const CategoriesPage = React.lazy(() => import('pages/Categories'));
const CategoryAdd = React.lazy(() => import('pages/Categories/Add'));
const CategoryEdit = React.lazy(() => import('pages/Categories/Edit'));
const CollectionsPage = React.lazy(() => import('pages/Collections'));
const CollectionAdd = React.lazy(() => import('pages/Collections/Add'));
const CollectionEdit = React.lazy(() => import('pages/Collections/Edit'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/admin/login"
              layout={EmptyLayout}
              component={props => <Login {...props} />}
            />

            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                {/* <PrivateRoute exact path="/admin" component={DashboardPage} /> */}
                <PrivateRoute
                  exact
                  path="/admin/banners"
                  component={BannersPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/banners/adicionar"
                  component={BannerAdd}
                />
                <PrivateRoute
                  exact
                  path="/admin/banner/:id/editar"
                  component={BannerEdit}
                />
                <PrivateRoute
                  path="/admin/configuracoes"
                  component={SettingsPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/produtos"
                  component={ProductsPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/produtos/:id/editar"
                  component={ProductEdit}
                />

                <PrivateRoute
                  exact
                  path="/admin/produtos/:id/gerenciar-imagens"
                  component={ProductImagesList}
                />

                <PrivateRoute
                  exact
                  path="/admin/produtos/importacao"
                  component={ProductsImport}
                />

                <PrivateRoute
                  exact
                  path="/admin/categorias"
                  component={CategoriesPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/categorias/:id/editar"
                  component={CategoryEdit}
                />
                <PrivateRoute
                  exact
                  path="/admin/categorias/adicionar"
                  component={CategoryAdd}
                />

                <PrivateRoute
                  exact
                  path="/admin/colecoes"
                  component={CollectionsPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/colecoes/:id/editar"
                  component={CollectionEdit}
                />
                <PrivateRoute
                  exact
                  path="/admin/colecoes/adicionar"
                  component={CollectionAdd}
                />

                <PrivateRoute
                  exact
                  path="/admin/administradores"
                  component={AdminsPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/administradores/:id/editar"
                  component={AdminsEdit}
                />
                <PrivateRoute
                  exact
                  path="/admin/administradores/adicionar"
                  component={AdminsAdd}
                />
                <PrivateRoute
                  exact
                  path="/admin/usuarios"
                  component={UsersPage}
                />
                <PrivateRoute
                  exact
                  path="/admin/usuarios/:id/editar"
                  component={UsersEdit}
                />
                <PrivateRoute
                  exact
                  path="/admin/usuarios/adicionar"
                  component={UsersAdd}
                />
                <PrivateRoute path="*" component={NotFoundPage} />
              </React.Suspense>
            </MainLayout>
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
