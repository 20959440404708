import React from 'react';
import PropTypes from 'prop-types';
import { Input, FormFeedback } from 'reactstrap';

const propTypes = {};

const defaultProps = {};

export default function CustomInput(props) {
  const {
    field,
    form: { touched, errors },
    ...rest
  } = props;
  return (
    <>
      <Input
        invalid={!!(touched[field.name] && errors[field.name])}
        {...field}
        {...rest}
      />
      {touched[field.name] && errors[field.name] && (
        <FormFeedback>{errors[field.name]}</FormFeedback>
      )}
    </>
  );
}

CustomInput.propTypes = propTypes;
CustomInput.defaultProps = defaultProps;
