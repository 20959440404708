import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import logo200Image from 'assets/img/logo/logo-paris.png';
import { Formik, Field } from 'formik';
import { login } from 'services/endpoints';
import { setToken, setUserInfo } from 'services/auth';
import { showSuccess, showError } from 'utils/toasts';
import { validation } from './ValidationSchema';
import CustomInput from 'components/inputs/CustomInput';

const propTypes = {
  showLogo: PropTypes.string,
};

const defaultProps = {
  showLogo: true,
};

function Login(props) {
  const { children, touched, showLogo, history } = props;
  const [hasErrors, setHasErrors] = useState([]);

  async function handleLogin(values) {
    await login(values)
      .then(res => {
        showSuccess(res.data.message);
        setTimeout(() => {
          setToken({
            access_token: res.data.access_token,
            refresh_token: res.data.refresh_token,
          });
          setUserInfo({ email: res.data.data.email, nome: res.data.data.nome });
          history.push('/admin/configuracoes');
        }, 1000);
      })
      .catch(err => showError('Erro ao fazer o login'));
  }

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Col md={6} lg={4}>
        <Card body>
          <Formik
            initialValues={{ email: '', senha: '' }}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleLogin(values);
              actions.setSubmitting(false);
            }}
            validationSchema={validation}
            render={({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
            }) => (
              <Form>
                {showLogo && (
                  <div className="text-center pb-4">
                    <img
                      src={logo200Image}
                      className="rounded"
                      style={{
                        background: '#000',
                        width: 200,
                        height: 60,
                        cursor: 'pointer',
                      }}
                      alt="logo"
                      onClick={() => null}
                    />
                  </div>
                )}
                <FormGroup>
                  <Label for="email">E-mail</Label>
                  <Field
                    component={CustomInput}
                    type="email"
                    name="email"
                    id="email"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="senha">Senha</Label>
                  <Field
                    component={CustomInput}
                    type="password"
                    name="senha"
                    id="senha"
                  />
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> Lembrar
                  </Label>
                </FormGroup>
                <hr />
                <Button
                  size="lg"
                  className="bg-gradient-theme-left border-0"
                  block
                  onClick={() => handleSubmit()}
                >
                  Entrar
                </Button>
                {children}
              </Form>
            )}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default Login;

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;
