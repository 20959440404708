import logo200Image from 'assets/img/logo/logo-paris.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-7.jpg';
import React from 'react';
import {
  MdKeyboardArrowDown,
  MdSettings,
  MdPeople,
  MdWallpaper,
  MdShop,
  MdLocalOffer,
  MdPerson,
  MdBuild,
  MdImportExport,
  MdVibration,
  MdCollectionsBookmark,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Nav,
  Collapse,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navComponents = [
  {
    to: '/admin/produtos/importacao',
    name: 'importação',
    exact: true,
    Icon: MdImportExport,
  },
  {
    to: '/admin/produtos',
    name: 'gerenciamento',
    exact: true,
    Icon: MdBuild,
  },
];

const navItems = [
  // { to: '/admin', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/admin/banners', name: 'banners', exact: false, Icon: MdWallpaper },
  {
    to: '/admin/categorias',
    name: 'categorias',
    exact: false,
    Icon: MdLocalOffer,
  },
  {
    to: '/admin/colecoes',
    name: 'coleções',
    exact: false,
    Icon: MdCollectionsBookmark,
  },
  {
    to: '/admin/configuracoes',
    name: 'configurações',
    exact: false,
    Icon: MdSettings,
  },
  { to: '/admin/usuarios', name: 'usuários', exact: false, Icon: MdPeople },
  {
    to: '/admin/administradores',
    name: 'administradores',
    exact: false,
    Icon: MdPerson,
  },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <div className="class">
              <img
                src={logo200Image}
                width="200"
                height="60"
                className="pr-2"
                alt=""
              />
            </div>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            <NavItem
              className={bem.e('nav-item')}
              onClick={this.handleClick('Components')}
            >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdShop className={bem.e('nav-item-icon')} />
                  <span className="text-uppercase align-self-start">
                    Produtos
                  </span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e('nav-item-icon')}
                  style={{
                    padding: 0,
                    transform: this.state.isOpenComponents
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                    transitionDuration: '0.3s',
                    transitionProperty: 'transform',
                  }}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenComponents}>
              {navComponents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
